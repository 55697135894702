import React, { useEffect } from "react";
import {
  Container,
  Box,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import Login from "../components/Authentication/login";
import Signup from "../components/Authentication/signup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../components/slices/userInfoSlice";

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));

    if (user) {
      dispatch(setUserInfo(user));
      history.push("/chats");
    }
  }, [history]);
  //console.log(JSON.parse(localStorage?.getItem("userInfo")));
  // console.log(`userInfo`);
  return (
    <Container maxW="400px" centerContent>
      <Box
        d="flex"
        justifyContent="center"
        p={6}
        w="100%"
        m="40px 0 15px 0"
        borderRadius="lg"
      ></Box>
      <Box w="100%" p={4} borderRadius="lg" borderWidth="1px">
        <Tabs variant="soft-rounded">
          <TabList mb="1em">
            <Tab width="50%">Zaloguj</Tab>
            <Tab width="50%">Utwórz konto</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Login />
            </TabPanel>
            <TabPanel>
              <Signup />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};

export default HomePage;
